
import { defineComponent, reactive, onMounted } from "vue";
import store from "@/store";
import Content from "@/components/parts/Content.vue";
import { useRouter, onBeforeRouteUpdate, onBeforeRouteLeave } from "vue-router";
import { scrollFunc } from "@/components/contentsOperations";
import { updateContentsData } from "@/components/firebaseOperations";
import { pages } from "@/components/constants";

export default defineComponent({
  name: pages.soar,
  components: {
    Content,
  },
  setup() {
    const router = useRouter();
    const state = reactive({
      key: "",
      scrollFunc: scrollFunc(router.currentRoute.value.name), // スクロール時に実行されるクロージャ
    });

    onMounted(() => {
      if (
        !store.state.soaringContents[store.state.detailType].loadedEverything
      ) {
        // Soarページに関する、番組コンテンツのロードが完了していない場合
        window.addEventListener("scroll", state.scrollFunc); // 逐次追加のためのスクロールイベントを追加
      }
    });

    onBeforeRouteUpdate(() => {
      // Soarページ内で遷移する場合に実行される関数
      state.key += "*"; // ページ遷移時にコンテンツを更新するためには、for分に指定するkeyを更新する必要がある。そのために、state.keyを更新する。(state.keyは、for分のkeyに含まれているため、state.keyを変更すると、for分のkeyも変更される)
    });

    onBeforeRouteLeave(() => {
      window.removeEventListener("scroll", state.scrollFunc); // スクロールイベント削除
    });

    const init = async () => {
      if (
        store.state.soaringContents[store.state.detailType].values.length === 0
      ) {
        // 開こうとしているSoarページに関する、コンテンツデータがまだ1つもsetされていない場合
        await updateContentsData("Soar", false, store.state.detailType);
      }
    };

    init();

    return {
      state,
      store,
      router,
    };
  },
});
